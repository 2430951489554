var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":'Update Category: ' + _vm.nameCat,"before-change":_vm.validationForm}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-center",attrs:{"align":"center"}},[_c('h4',[_vm._v("Cover")]),_c('b-media',{staticClass:"mb-1 mr-auto"},[_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.img,"size":"180px"}})],1),_c('div',{staticClass:"mb-4"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.uploadImage}}),_c('feather-icon',{staticClass:" ",attrs:{"icon":"EditIcon"}})],1)],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-center",attrs:{"align":"center"}},[_c('h4',[_vm._v("Icon")]),_c('b-media',{staticClass:"mb-1"},[_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.img2,"size":"180px"}})],1),_c('div',{staticClass:"mb-4"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl2.click()}}},[_c('input',{ref:"refInputEl2",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.uploadImage2}}),_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Please Enter Code'),expression:"'Please Enter Code'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Code","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"code","state":errors.length > 0 ? false : null},model:{value:(_vm.data.code),callback:function ($$v) {_vm.$set(_vm.data, "code", $$v)},expression:"data.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Please Enter Weight'),expression:"'Please Enter Weight'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Weight","label-for":"Weight"}},[_c('validation-provider',{attrs:{"name":"weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"weight","state":errors.length > 0 ? false : null},model:{value:(_vm.data.weight),callback:function ($$v) {_vm.$set(_vm.data, "weight", $$v)},expression:"data.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category Activation","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.data.active),callback:function ($$v) {_vm.$set(_vm.data, "active", $$v)},expression:"data.active"}},[(_vm.data.active)?_c('p',[_vm._v(" Category Active ")]):_c('p',[_vm._v("Category DisActive")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Can Sell C2C Products ?","label-for":"is_c2c_sell"}},[_c('validation-provider',{attrs:{"name":"is_c2c_sell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.data.is_c2c_sell),callback:function ($$v) {_vm.$set(_vm.data, "is_c2c_sell", $$v)},expression:"data.is_c2c_sell"}},[(_vm.data.is_c2c_sell)?_c('p',[_vm._v(" Can Sell C2C Products ")]):_c('p',[_vm._v("Can't Sell C2C Products")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Can Sell B2C Products ?","label-for":"is_b2c_sell"}},[_c('validation-provider',{attrs:{"name":"is_b2c_sell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.data.is_b2c_sell),callback:function ($$v) {_vm.$set(_vm.data, "is_b2c_sell", $$v)},expression:"data.is_b2c_sell"}},[(_vm.data.is_b2c_sell)?_c('p',[_vm._v(" Can Sell B2C Products ")]):_c('p',[_vm._v("Can't Sell B2C Products")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Alert ")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,indexVal){return _c('li',{key:indexVal},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }